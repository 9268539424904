<template>
    <div class="aops-asset-add-tags add-tag">
        <div class="aop-tags-title">
            添加标签
            <span class="aop-tags-title-suggest">建议标签数量不超过5个，单个英文标签长度不超过12个字符长度，单个中文标签长度不超过6个字符长度</span>
        </div>
        <template v-for="item in tagData">
            <div :key="item.type">
                <div class="aop-tags-input-wrap">
                    {{item.name}}
                    <el-input
                        v-model="form[item.prop]"
                        class="aop-tags-input"
                        :placeholder="item.placeholder"
                    >
                        <i
                            slot="suffix"
                            class="el-input__icon el-icon-plus"
                            @click="addTags(item.type)"
                        ></i>
                    </el-input>
                </div>
                <div class="aop-tags-list">
                    <el-tag
                        v-for="(tag, index) in getData(item.type)"
                        :key="index"
                        closable
                        :disable-transitions="false"
                        @close="tagRemove(tag, item.type)"
                    >{{ tag }}</el-tag>
                </div>
            </div>

        </template>
    </div>
</template>

<script>
export default {
    props: {
        tags: {
            type: Array,
            default: () => []
        },
        chineseTags: {
            type: Array,
            default: () => []
        }
    },
    /**
     * data
     * @return {object}
    */
    data() {
        return {
            tagData: [{
                type: 'en',
                name: '英文标签',
                placeholder: 'Enter here',
                prop: 'tagName'
            }, {
                type: 'cn',
                name: '中文标签',
                placeholder: '请输入',
                prop: 'tagName_cn'
            }],
            form: {
                tagName: '',
                tagName_cn: ''
            }
        };
    },
    methods: {
        /**
         * 添加标签
         * @param {string} type 类型 中文/英文
         * @returns {Array}
        */
        getData(type) {
            return type === 'en' ? this.tags : this.chineseTags;
        },
        /**
         * 添加标签
         * @param {string} type 类型 中文/英文
        */
        addTags(type) {
            if (type === 'en') {
                this.addTagsEn();
            } else {
                this.addTagsCn();
            }
        },
        /**
        * 添加标签 英文标签
        * @returns {*}
        */
        addTagsEn() {
            const tagName = this.form.tagName;
            const reg = /^[a-zA-Z]*$/;
            if (tagName === '') {
                this.$$error('标签名称不能为空');
                return false;
            }
            if (!reg.test(tagName)) {
                this.$$error('仅支持字母');
                return;
            }
            if (this.tags.length === 5) {
                this.$$error('标签数量不能大于五');
                return false;
            }
            if (tagName.length > 12) {
                this.$$error('英文标签名称不能超过12位字符');
                return false;
            }
            this.tags.push(this.form.tagName);
            this.form.tagName = '';
        },
        /**
        * 添加标签 中文
        * @returns {*}
        */
        addTagsCn() {
            const reg = /^[\u4e00-\u9fa5]*$/;
            const tagName = this.form.tagName_cn;
            if (tagName === '') {
                this.$$error('标签名称不能为空');
                return false;
            }
            if (!reg.test(tagName)) {
                this.$$error('仅支持中文');
                return;
            }
            if (this.chineseTags.length === 5) {
                this.$$error('标签数量不能大于五');
                return false;
            }
            if (tagName.length > 6) {
                this.$$error('中文标签名称不能超过6位字符');
                return false;
            }
            this.chineseTags.push(this.form.tagName_cn);
            this.form.tagName_cn = '';
        },
        /**
         * 删除标签
         * @param {string} tag 要删除的tag
         * @param {string} type 类型 中文/英文
        */
        tagRemove(tag, type) {
            if (type === 'cn') {
                this.chineseTags.splice(this.chineseTags.indexOf(tag), 1);
            } else {
                this.tags.splice(this.tags.indexOf(tag), 1);
            }
        }
    }
};
</script>
<style lang="less">
@import "./assetAddTags.less";
</style>
