<template>
  <div class="aops-assets-model-property">
    <div class="aop-model-property-title">
      模型属性
    </div>
    <div>
      <el-form-item
        label="资产类型"
        label-width="100px"
        prop="type"
      >
        <el-select
          v-model="asset.type"
          placeholder="请选择"
        >
          <el-option
            v-for="item in assetTypeOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div>
      <el-form-item
        label="长宽高"
        prop="size"
        :required="is3D"
      >
        <el-input
          v-model="asset.size"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </div>
    <div>
      <el-form-item
        label="是否封闭"
        prop="model_state"
        :required="is3D"
      >
        <el-select
          v-model="asset.model_state"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="item in modelStateOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        asset: {
            type: Object,
            /**
             * asset默认值
             * @return {object}
            */
            default() {
                return {};
            }
        },
        is3D: {
            type: Boolean
        }
    },
    /**
     * data
     * @return {object}
    */
    data() {
        return {
            // 资产类型选项
            assetTypeOption: [
                {
                    value: '3D Assets',
                    label: '3D模型'
                },
                {
                    value: 'Surface',
                    label: '贴图'
                },
                {
                    value: 'Decals',
                    label: '透贴'
                }
            ],
            modelStateOption: [
                {
                    value: 0,
                    label: '封闭'
                },
                {
                    value: 1,
                    label: '不封闭'
                }
            ]
        };
    }
};
</script>
<style lang="less">
@import "./modelType.less";
</style>
