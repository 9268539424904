var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aops-asset-classify"},[_c('div',{staticClass:"aop-classify-title"},[_vm._v("添加分类 ")]),_c('el-cascader',{ref:"cascader",attrs:{"options":_vm.datalist,"props":{
            expandTrigger: 'hover',
            children: 'child',
            label: 'name_en',
            value: 'id',
            leaf: 'is_leaf',
            emitPath: false
        }},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.addClassification}},[_vm._v("添加")]),_c('div',_vm._l((_vm.tags),function(tag){return _c('el-tag',{key:tag,staticClass:"mg-r-10 mg-b-8",attrs:{"closable":"","disable-transitions":false},on:{"close":function($event){return _vm.delClassification(tag)}}},[_vm._v(_vm._s(tag))])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }