<template>
    <div class="aops-asset-classify">
        <div class="aop-classify-title">添加分类 </div>
        <el-cascader
            ref="cascader"
            v-model="value"
            :options="datalist"
            :props="{
                expandTrigger: 'hover',
                children: 'child',
                label: 'name_en',
                value: 'id',
                leaf: 'is_leaf',
                emitPath: false
            }"
        ></el-cascader>
        <el-button
            type="primary"
            size="medium"
            @click="addClassification"
        >添加</el-button>
        <div>
            <el-tag
                v-for="tag in tags"
                :key="tag"
                class="mg-r-10 mg-b-8"
                closable
                :disable-transitions="false"
                @close="delClassification(tag)"
            >{{ tag }}</el-tag>
        </div>
    </div>
</template>

<script>
import classifyFilter from '@/mixins/asset/classify.js';
// 资产添加分类
export default {
    mixins: [classifyFilter],
    props: {
        list: {
            type: Array,
            default: () => ([])
        },
        tags: {
            type: Array,
            default: () => ([])
        }
    },
    /**
     * data
     * @returns {object}
    */
    data() {
        return {
            value: []
        };
    },
    computed: {
        /**
         * datalist
         * @returns {*}
         */
        datalist() {
            const _list = this.handleFilterData(this.list);
            return _list || [];
        }
    },

    methods: {
        /**
         * 添加分类
        */
        addClassification() {
            if (this.value.length === 0) {
                this.$$warning('请选择分类');
                return;
            }
            const select = this.$refs.cascader.inputValue.replace(' / ', '-');
            if (this.tags.includes(select)) {
                this.$$warning('请勿添加相同的分类');
                return;
            }
            this.$emit('onAdd', { value: this.value, label: select });
        },
        /**
         * 删除分类
         * @param {string} value val
        */
        delClassification(value) {
            this.$emit('onDel', value);
        }
    }
};
</script>
<style lang="less">
@import "./assetClassify.less";
</style>
